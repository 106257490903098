import React, { useContext, useRef } from "react"

import Seo from "../components/seo"

import Row from "../components/themableUI/row"
import Col from "../components/themableUI/col"

import Hero from "../components/hero"
import About from "../components/about"
import Contact from "../components/contact"
import Scope from "../components/scope/scope"

import { ThemeContext } from "styled-components"

const IndexPage = () => {
  const aboutRef = useRef()
  const contactRef = useRef()
  const themeContext = useContext(ThemeContext)

  return (
    <>
      <Scope
        aboutRef={aboutRef}
        contactRef={contactRef}
        color={themeContext.color1}
        color2={themeContext.color2}
        eraseColor={themeContext.backgroundColor}
        scrollMin={themeContext.animationIsEnabled ? 0.01 : 0.01}
        scrollMax={themeContext.animationIsEnabled ? 0.09 : 0.0100000002}
        step={themeContext.animationIsEnabled ? 0.02 : 0.00001}
      />

      <Seo title="Home" />

      <Hero></Hero>
      <div id="about" ref={aboutRef}>
        <About></About>
      </div>
      <Row>
        <Col size={12}>
          <div id="contact" ref={contactRef}>
            <Contact></Contact>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default IndexPage

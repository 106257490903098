import React, { useEffect, useContext, useRef } from "react"

import { ThemeContext } from "styled-components"

import "./scope.css"

function getScrollAmount() {
  var h = document.documentElement,
    b = document.body,
    st = "scrollTop",
    sh = "scrollHeight"
  return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)
}
const Scope = ({
  step,
  stepRatio,
  tuningRatio,
  frequency,
  gain,
  eraseColor,
  color,
  lineWidth,
  throttle,
  scrollMin,
  scrollMax,
  color2,
  lineWidth2,
  aboutRef,
  contactRef,
}) => {
  const ref = useRef()
  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    function handleScroll() {
      contactRef.current.getBoundingClientRect().top <=
      contactRef.current.getBoundingClientRect().height / 2
        ? themeContext.setLink("contact")
        : aboutRef.current.getBoundingClientRect().top <=
          aboutRef.current.getBoundingClientRect().height / 2
        ? themeContext.setLink("about")
        : themeContext.setLink("idk")
    }

    let canvas = ref.current
    let context = canvas.getContext("2d")

    let then = Date.now()
    let now

    let requestId
    const render = context => {
      requestId = requestAnimationFrame(() => render(context))

      now = Date.now()

      if (now > then + throttle) {
        then = now

        handleScroll()
      }
    }

    render(context)

    return () => {
      cancelAnimationFrame(requestId)
    }
  }, [aboutRef, contactRef, throttle, themeContext])

  useEffect(() => {
    let canvas = ref.current
    let context = canvas.getContext("2d")

    let height = context.canvas.height
    let width = context.canvas.width

    let then = Date.now()
    let now

    let offsetX = 0
    let offsetX2 = 0

    let requestId
    const render = context => {
      requestId = requestAnimationFrame(() => render(context))

      now = Date.now()

      if (now > then + throttle) {
        then = now

        const newFrequency =
          frequency +
          ((scrollMax - scrollMin) * (getScrollAmount() || 1) + scrollMin)

        context.fillStyle = eraseColor

        context.fillRect(0, 0, width, height)

        context.beginPath()

        context.lineWidth = lineWidth

        context.strokeStyle = color

        var x = 0
        var y = 0
        // var x2 = 0;
        var y2 = 0

        var amplitude = (height / 2) * gain
        while (x < width) {
          y =
            height / 2 +
            amplitude * Math.sin((x + offsetX) / (newFrequency * 1))
          y2 =
            height / 2 +
            amplitude * Math.sin((x + offsetX2) / (newFrequency * tuningRatio))

          context.lineTo(y, y2)
          x++
        }

        context.stroke()

        context.lineWidth = lineWidth2

        context.strokeStyle = color2

        x = 0
        y = 0
        y2 = 0

        while (x < width) {
          y =
            height / 2 +
            amplitude * Math.sin((x + offsetX) / (newFrequency * 1))
          y2 =
            height / 2 +
            amplitude * Math.sin((x + offsetX2) / (newFrequency * tuningRatio))

          context.moveTo(y, y2)

          context.lineTo((y + 50) % width, (y2 + 50) % width)
          x++
        }

        context.stroke()

        offsetX += step
        offsetX2 += step * stepRatio
      }
    }

    render(context)

    return () => {
      cancelAnimationFrame(requestId)
    }
  }, [
    aboutRef,
    color,
    color2,
    contactRef,
    eraseColor,
    frequency,
    gain,
    lineWidth,
    lineWidth2,
    scrollMax,
    scrollMin,
    step,
    stepRatio,
    throttle,
    tuningRatio,
  ])

  return <canvas ref={ref} width="800" height="800" />
}

Scope.defaultProps = {
  color: "rgba(0, 255, 0, 0.3",
  color2: "rgba(204, 0, 153, 0.2)",
  eraseColor: "white",
  lineWidth: 2,
  lineWidth2: 1,
  frequency: 0.01,
  tuningRatio: 2,
  gain: 0.6,
  step: 0.02,
  stepRatio: 1.025,
  throttle: 0,
  scrollMin: 0.01,
  scrollMax: 0.09,
}

export default Scope

import React from "react"
import { Link } from "gatsby"

import styled, { css } from "styled-components"
import { getObjectStyles, merge } from "./themableUI/utilities/themify"

const buttonStyles = css`

display: inline-block; font-weight: 400; line-height: 1.5; color: #F2F2F2; text-align: center; text-decoration: none; vertical-align: middle; cursor: pointer; user-select: none; background-color: #616C8C; border: 1px solid transparent; padding: 0.35rem 1.5rem; font-size: 1rem; border-radius: 0; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 

&:hover { color: white; background-color: #95A0BF; } 
&:focus { outline: 0; box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); } 
&:disabled, &.disabled { pointer-events: none; opacity: 0.65; } 

font-family: 'Manrope', sans-serif; text-transform: uppercase; box-shadow: 2px 2px #7C8C5A; 

  ${props => {
    const mergedThemes = props.theme.button
      ? merge(
          StyledButton.defaultProps.theme.button.styles,
          props.theme.button.styles
        )
      : StyledButton.defaultProps.theme.button.styles
    return getObjectStyles(mergedThemes)
  }}

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`

const StyledButton = styled(Link)`
  ${buttonStyles}
`

export default function LinkButton({ children, onClick, style, to }) {
  return (
    <StyledButton to={to} style={style} onClick={onClick}>
      {children}
    </StyledButton>
  )
}

StyledButton.defaultProps = {
  theme: {
    button: {
      styles: {
       
      },
    },
  },
}

import React from "react"

import { Link } from "gatsby"

import Row from "./themableUI/row"
import Col from "./themableUI/col"

import LinkButton from "./link-button"

const About = () => (
  <>
    <h2 className="screen-reader-text">about</h2>

    <Row>
      <Col size={12}>
        <p className="aboutText">
          I am a self-taught coder, who enjoys creating user experiences for the
          web and mobile. You can check out some examples of my work{" "}
          <Link to={"/work"}>here.</Link>
        </p>
        <p className="aboutText">
          If you would like to work with me, then please get in touch...
        </p>
        <LinkButton to={"/#contact"}>Contact Me</LinkButton>
      </Col>
    </Row>
  </>
)

export default About

import React from "react"

import Row from "./themableUI/row"
import Col from "./themableUI/col"

import ContactForm from "./contact-form"

const Contact = () => (
  <>
  <h2 className="screen-reader-text">Contact</h2>
  <Row style={{width: '100vw'}}>
    <Col size={12} sizes={{ sm: 6 }}>
      
      <h2 className="bannerText contactText">I'd love to hear from you...</h2>
    </Col>
    <Col style={{padding: 0}} size={12} sizes={{ sm: 6 }}>
      <ContactForm></ContactForm>
    </Col>
  </Row>
  </>
)

export default Contact
